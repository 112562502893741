<!-- 个人中心 -->
<template>
	<div class="myCenter-page">
		<div class="top-wrap">
			<div class="top-content">
				<div class="user-wrap flex-ac">
					<img class="avatar" src="../assets/image/icon-userCover.png" alt="" />
					<div class="user-name">{{ userInfo.s_name }}</div>
				</div>
			</div>
		</div>
		<div class="center-container flex">
			<div class="aside">
				<div class="list">
					<div class="nav-item flex-ac" @click="clickNav(item)" v-for="(item, index) in navList" :key="index" :class="{ active: activeNavIndex == item.id }">
						<img :src="item.icon" alt="" class="icon" />
						<div class="nav-title">{{ item.title }}</div>
					</div>
					<div class="nav-item flex-ac" @click="logout">
						<img src="../assets/image/icon-exit.png" alt="" class="icon" />
						<div class="nav-title">登出账户</div>
					</div>
				</div>
			</div>
			<div class="main">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "MyCenter",
	data() {
		return {
			activeNavIndex: 0,
			navList: [
				{
					id: 0,
					title: "我的课程",
					icon: require("../assets/image/icon-course.png"),
					activeIcon: "",
					path: "course",
				},
				{
					title: "我的收藏",
					id: 1,
					icon: require("../assets/image/icon-collect.png"),
					activeIcon: "",
					path: "collect",
				},
				{
					title: "个人信息",
					id: 2,
					icon: require("../assets/image/icon-personal.png"),
					activeIcon: "",
					path: "myInfo",
				},
			],
		};
	},
	components: {},

	computed: {
		userInfo() {
			return this.$store.getters.useInfo;
		},
	},

	created() {
		if (this.userInfo.s_type == 1) {
			this.navList.push({
				title: "修改密码",
				id: 3,
				icon: require("../assets/image/icon-lock1.png"),
				activeIcon: "",
				path: "changePassword",
			});
		}
		if (this.$route.path == "/myCenter/collect") {
			this.activeNavIndex = 1;
		} else if (this.$route.path == "/myCenter/myInfo") {
			this.activeNavIndex = 2;
		} else if (this.$route.path == "/myCenter/changePassword") {
			this.activeNavIndex = 3;
		} else {
			this.activeNavIndex = 0;
		}
	},

	methods: {
		clickNav(item) {
			if (this.activeNavIndex == item.id) return;
			this.activeNavIndex = item.id;
			this.$router.push({
				path: `/myCenter/${item.path}`,
			});
		},
		logout() {
			this.$store.commit("UPDATEDSTATE", { key: "useInfo", value: {} });
			localStorage.clear();
			this.$router.push({ path: "/login" });
		},
	},
};
</script>

<style scoped lang="less">
.top-wrap {
	height: 180px;
	background-image: url("../assets/image/centerpage-bg.png");
	background-repeat: no-repeat;
	background-size: 100% 180px;
	.top-content {
		width: 1200px;
		height: 180px;
		position: relative;
		margin: 0 auto;
		.user-wrap {
			position: absolute;
			bottom: -40px;
			font-size: 34px;
			color: #ffffff;
			.avatar {
				width: 150px;
				height: 150px;
				border-radius: 50%;
				margin-right: 30px;
			}
		}
	}
}
.center-container {
	width: 1200px;
	margin: 0 auto 80px;
	.aside {
		width: 220px;
		padding-top: 80px;
		.list {
			.nav-item {
				width: 170px;
				height: 48px;
				margin-bottom: 10px;
				cursor: pointer;
				font-size: 18px;
				color: #93999f;
				padding-left: 26px;
				.icon {
					width: 22px;
					margin-right: 10px;
				}
				&:hover {
					color: #15abee;
				}
				&.active {
					background-image: url("../assets/image/center-nav-bg.png");
					background-repeat: no-repeat;
					background-size: 100%;
					color: #fff;
				}
				&.active:hover {
					color: #fff;
				}
			}
		}
	}
	.main {
		width: 980px;
	}
}
</style>
